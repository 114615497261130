import c from 'classnames';

import { HeadingTags } from 'types/html-types';

import s from './Heading.module.scss';
import { useRef } from 'react';
import { useIsTouchDevice } from 'hooks/useIsTouchDevice';

type HeadingProps = {
  type?: HeadingTags;
  as?: HeadingTags;
  className?: string;
  style?: React.CSSProperties;
  skipGradient?: boolean;
  children: React.ReactNode;
};

const Heading = ({
  type = 'h1',
  as,
  children,
  className,
  style,
  skipGradient = false,
}: HeadingProps) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const isTouchDevice = useIsTouchDevice();

  if (!children) {
    return null;
  }

  const Wrap = as ?? type;

  const handleMouseMove = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (isTouchDevice) {
      return;
    }

    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();

    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;

    ref.current?.style.setProperty('--x', `${x}%`);
    ref.current?.style.setProperty('--y', `${y}%`);
  };

  return (
    <Wrap
      ref={ref}
      className={c(s[type], className, { [s.gradientTitle]: !skipGradient })}
      style={style}
      onMouseMove={handleMouseMove}
    >
      {children}
    </Wrap>
  );
};

export const H1 = (props: HeadingProps) => <Heading type="h1" {...props} />;

export const H2 = (props: HeadingProps) => <Heading type="h2" {...props} />;

export const H3 = (props: HeadingProps) => <Heading type="h3" {...props} />;

export const H4 = (props: HeadingProps) => <Heading type="h4" {...props} />;

export const H5 = (props: HeadingProps) => <Heading type="h5" {...props} />;

export const H6 = (props: HeadingProps) => <Heading type="h6" {...props} />;
