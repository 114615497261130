import { useEffect, useState } from 'react';

export const useIsTouchDevice = (): boolean => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const checkTouch =
      'ontouchstart' in window ||
      (window.matchMedia && window.matchMedia('(pointer: coarse)').matches);

    setIsTouchDevice(checkTouch);
  }, []);

  return isTouchDevice;
};
